@font-face {
  font-family: 'Roboto Regular';
  src: local('Muli Regular'), url('./assets/fonts/roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto Medium';
  src: local('Roboto Medium'), url('./assets/fonts/roboto/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'Roboto Black';
  src: local('Roboto Black'), url('./assets/fonts/roboto/Roboto-Black.ttf');
}

@font-face {
  font-family: 'Roboto Bold';
  src: local('Roboto Bold'), url('./assets/fonts/roboto/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Muli Regular';
  src: local('Muli Regular'), url('./assets/fonts/muli/Muli.ttf');
}

@font-face {
  font-family: 'Muli Black';
  src: local('Muli Black'), url('./assets/fonts/muli/Muli-Black.ttf');
}

@font-face {
  font-family: 'Muli Bold';
  src: local('Muli Bold'), url('./assets//fonts/muli/Muli-Bold.ttf');
}
